import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Image, Button, Typography } from "antd";
import axios from "axios";

import "./index.scss";
import loadingImg from "../../assets/loading.svg";

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

export default function Home() {
  const currentYear = new Date().getFullYear();
  const [state, setState] = useState({ src: loadingImg });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchImage();
  }, []);

  const fetchImage = async () => {
    try {
      setIsLoading(true);
      await axios({
        method: "GET",
        url: "https://api.thecatapi.com/v1/images/search",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "d84c3485-4dff-4da4-9e8e-d920b68787bc",
        },
      }).then((res) => {
        setState({ src: res.data[0].url });
        setTimeout(() => setIsLoading(false), 2000);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Layout>
        <Header className="header">
          <Title level={3} className="title">
            Meow Generator
          </Title>
        </Header>

        <div className="content">
          <Content className="content-mid">
            <Row>
              <Col xs={1} md={4} lg={5}></Col>
              <Col className="column-mid" xs={22} md={16} lg={14}>
                {isLoading ? (
                  <Image className="img imgLoading" src={state.src} alt="cat" />
                ) : (
                  <Image className="img" src={state.src} alt="cat" />
                )}
              </Col>
              <Col xs={1} md={4} lg={5}></Col>
            </Row>
          </Content>
          <Content className="content-bot">
            <Button
              className="button"
              type="primary"
              shape="round"
              loading={isLoading}
              onClick={fetchImage}
            >
              MEOW 😻
            </Button>
          </Content>
        </div>

        <Footer className="footer">
          Meow Generator &copy; {currentYear} Tam Chau
        </Footer>
      </Layout>
    </>
  );
}
